@use "../variables";
@use "../mixins";

.modal--address {

    .input-field__label {
        color: variables.$neutral-darker;
    }

    .modal__title {
        font-size: variables.$font-size-xl;
        color: variables.$neutral-darker;
        font-weight: variables.$font-weight-normal;
    }

    .modal {


        &__content {
            max-width: 600px;
        }

        &__header,
        &__body {
            padding-left: 32px;
            padding-right: 32px;
        }

        &__header {
            align-items: center;
        }
    }
}

.modal-courier {


    .modal {


        &__header {
            align-items: center;
            padding: 0;
            margin-left: 24px;
        }

        &__title {
            font-size: variables.$font-size-xl;
            color: variables.$neutral-darker;
        }

        &__wrapper {
            height: 100%;
            position: relative;
            display: grid;
            grid-template-rows: 80px 1fr;
        }

        &__body {
            height: 100%;
            padding: 0;
        }
    }

    .courier {
        position: relative;
        width: 100%;
        height: 100%;

        &__wrapper {
            position: relative;
            height: 100%;
        }
    }
}

.pac-container {
    z-index: variables.$zindex-panel-overlay;
}

.courier-window {

    &__text {
        font-size: variables.$font-size-sm;

        .icon {
            padding-left: 0;
        }
    }

    &__title {
        font-size: variables.$font-size-sm;
        font-weight: variables.$font-weight-bold;
        color: variables.$neutral-darker;
        margin-bottom: 16px;
        max-width: 90%;
    }


    &__button {
        margin-top: 40px;
    }
}

.courier {
    .gm-ui-hover-effect {
        top: 8px !important;
        right: 16px !important;
        color: variables.$neutral-lighter-100 !important;
    }

    .gm-ui-hover-effect img {
        width: 24px !important;
        height: 24px !important;
        margin: 0 !important;
        position: relative;
    }
}

.delivery-bonus {
    border-radius: variables.$border-radius-round;

    &--ltblue {
        background: variables.$primary-lighter-10;
    }

    .checkbox {
        display: block;
    }
}

.limited-shipping-modal {
    @include mixins.modal-general;
}