@use "../../variables";

.my-page-overview {
    .my-page-alert {
        padding: .5rem 1rem;
    }

    .my-page-alert__button {
        .button {
            background-color: transparent;
        }
    }

    &__container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 32px;
    }
}

.myinfo-logged-out {
    text-align: center;

    h2 {
        padding: 50px;
        color: variables.$supporting-01-dark;
    }

    .container {
        pointer-events: none;
    }
}

@media(max-width: variables.$responsive-break - 1) {
    .my-page-overview {
        .title__container {
            display: flex;
        }

        &__container {
            grid-template-columns: 1fr;
            grid-row-gap: 0;
        }
    }
}
