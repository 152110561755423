@use "../../variables";
@use "../../mixins";

.my-info {
    & > div,
    .change-password {
        margin: 1rem 0;
    }

    .register {
        margin-left: 0;
    }

    h3 {
        text-transform: uppercase;
        margin-bottom: 1rem;
        text-align: center;
        flex: 1;
    }

    .dropdown {
        &__selected,
        &__list-item {
            font-size: variables.$font-size-default;
        }
    }

    .my-contacts__container {
        padding-bottom: 0;
    }

    .other-info {
        &__container {
            align-items: center;
        }
    }
    .social-auth{
        display:grid;
    }
}

.name-change {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px 0;

    &.d-none {
        display: none;
    }

    .button {
        width: 100%;
    }
}

@media (max-width: variables.$responsive-break - 1) {
    .my-info {
        .col-6 {
            max-width: unset;
            flex-basis: unset;
        }

        .change-password {
            background-color: variables.$neutral-lighter-25;

            &__container {
                width: 100%;
                flex-direction: column;
            }
        }

        .input-field,
        .address-search {
            flex-direction: column;
        }

        .input-field {
            &__label {
                padding: 0 0 0.5rem 0;
                color: variables.$neutral-light;
            }
        }

        .shipping-info,
        .change-password,
        .other-info {
            h3 {
                @include mixins.responsive-title;
            }
        }

        .title__container {
            padding-top: 0;
        }
    }
}
