@use "../../../variables";

.client-status {
  display: flex;
  flex-flow: row;
  width: 100%;
  min-height: 200px;

  .silver-client {
    color: variables.$neutral-lighter-100;
    font-weight: 800;
  }
  .gold-client {
    color: variables.$secondary-dark;
    font-weight: 800;
  }
}

.client-status__color {
  flex: 1 0 6%;
  text-align: center;
  padding-top: 1.5rem;
  &--silver {
    background: linear-gradient(180deg, variables.$neutral-dark 0%, variables.$neutral-lighter-50 100%);
  }
  &--loyal {
    background: linear-gradient(114.54deg, variables.$primary 21.56%, variables.$primary-light 78.44%);
  }
  &--gold {
    background: linear-gradient(180deg, variables.$secondary-darker 0%, variables.$secondary-light 100%);
  }
  &--vip {
    background: linear-gradient(180deg, variables.$secondary-darker 0%, variables.$secondary-light 100%);
  }
}

.client-status__content {
  background-color: variables.$white;
  flex: 1 0 94%;
}

.client-status__item {
  &-label {
    color: variables.$neutral-dark;
    line-height: 24px;
    display: flex;
    align-items: baseline;
  }
  &-info {
    color: variables.$neutral-darker;
    font-size: 24px;
    font-weight: 800;
    line-height: 150%;
  }
  &-bonus-list {
    padding-top: .5rem;
    font-weight: 800;
    line-height: 1.5;
    .inactive {
      color: variables.$neutral-lighter-100;
    }
  }
}
