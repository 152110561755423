@use "../../../variables";

.history-product__accessories {
    border-top: 1px dashed variables.$neutral-lighter-50;
    padding: 16px 0 16px 0;

    &-button {
        .open-icon {
            .icon {
                -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
                transition: all 0.5s ease;
            }
        }

        .icon {
            transition: all 0.5s ease;
        }
    }
}

@media (min-width: variables.$responsive-break) {
    .history-product__accessories {
        grid-template-columns: 1fr 1fr 4fr 2fr 2fr;
        grid-template-rows: repeat(2, 1fr);
        grid-template-areas:
            ". image name addToCart price" ". image code addToCart .";

        .history-accessory {
            &__image {
                grid-area: image;
            }

            &__name {
                grid-area: name;
                font-weight: variables.$font-weight-bold;
            }

            &__code {
                grid-area: code;
            }

            &__add-to-cart {
                grid-area: addToCart;

                .button {
                    width: 100%;
                }
            }

            &__price {
                grid-area: price;
                justify-self: flex-end;
            }
        }
    }
}

@media (max-width: variables.$responsive-break - 1) {
    .history-product__accessories {
        grid-template-columns: 1fr 2fr 1fr;
        grid-template-rows: repeat(2, auto);
        grid-template-areas: "image name name" ". price addToCart";
        padding: 16px;
        border-radius: 5px;
        border: 1px solid variables.$primary;
        border-top: 1px solid variables.$primary;
        margin-bottom: 8px;

        .history-accessory {
            &__image {
                grid-area: image;
            }

            &__name {
                grid-area: name;
                font-size: variables.$font-size-md;
                color: variables.$primary;
                font-weight: variables.$font-weight-normal;
            }

            &__code {
                display: none;
            }

            &__add-to-cart {
                grid-area: addToCart;

                .button {
                    width: 100%;
                    background-color: variables.$white;
                    
                    .button__text {
                        display: none;
                    }
                }
            }

            &__price {
                grid-area: price;
                font-size: variables.$font-size-md;
                font-weight: variables.$font-weight-bold;
            }
        }
    }
}
