.my-limit-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.my-limit-amount {
    display: flex;
    align-items: center;

    .input-field__input-container {
        flex-direction: row;
    }
}
