@use "../../../variables";
@use "../../../mixins";

.my-review {
    @include mixins.my-overview-section;

    &__content {
        padding: 0rem 1.5rem;
    }

    &__product {
        $my-review-product-border: solid 1px variables.$neutral-lighter-50;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top: $my-review-product-border;
        border-bottom: $my-review-product-border;
        padding: 1.25rem 0;
        margin-bottom: 2rem;

        & > * {
            margin-right: 1rem;
        }

        img {
            width: 60px;
            height: auto;
        }
    }

    &__rating {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .review-rating__stars {
        display: flex;
    }
}

.button-with-star .button__text{
    width: inherit;
}