@use "../../../variables";

.saved-shipping-location {

    &__header {
        display: flex;
        justify-content: space-between;
        pointer-events: auto;
        cursor: pointer;
        padding: 1rem;
        width: 100%;

        &:hover {
            background-color: variables.$neutral-lighter-25;
        }

        div:nth-child(1) {
            flex: 3;
        }

        div:nth-child(2) {
            flex: 4;
        }

        div:nth-child(3) {
            flex: 1;
        }
    }

    &__header-icon {
        text-align: right;
        color: variables.$primary;
    }

    &__body {
        display: none;
        margin-top: 2rem;

        &--open {
            display: block !important;

            .add-location {
                display: block;
            }
        }

        .add-location-form {
            padding-left: 10%;
        }
    }

    &__delete-container {
        padding-top: 4px;
    }

    .add-location__type {
        display: none;
    }
}
