@use "../../../variables";
@use "../../../mixins";

.coupons {
    background: green;
    @include mixins.my-overview-section;
    grid-column: 1 / 4;
    padding: 32px;

    &__header {
        font-size: variables.$font-size-lg;
        justify-content: space-between;
    }
    &__title{
        font-size: variables.$font-size-lg;
    }

    &__content {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 16px;
    }

    &__header, &__title, &__code, {
        font-weight: variables.$font-weight-bolder;
        color: variables.$primary;
    }

    &__tooltip {
        position: absolute;
        right: 8px;
        top: 8px;
    }

    .tooltip__button .icon {
        color: variables.$neutral-dark;
    }

    &__item {
        background-image: url('/images/coupon-background.svg');
        background-repeat: no-repeat;
        background-position: right top;
        height: 120px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding: 16px;
        border-radius: 8px;

        &:nth-child(4n+1) {
            background-color: variables.$secondary;
        }

        &:nth-child(4n+2) {
            background-color: #00D0FF;
        }

        &:nth-child(4n+3) {
            background-color: #16EAD9;
        }

        &:nth-child(4n) {
            background-color: #47C1FA;
        }
    }
}