@use "../../../variables";
@use "../../../mixins";

$expandable-heading-side-padding: 24px;

.expandable {
    width: 100%;
    border: solid 1px variables.$neutral-lighter-50;
    border-radius: 5px;
}

.expandable-header {
    display: grid;
    background-color: variables.$white;

    &__item {
        display: flex;
    }

    &__open-button {
        .icon {
            font-size: 1.5rem;
        }
    }

    &__thumbnail {
        width: 2rem;
        height: auto;
        padding-right: 1rem;
    }

    &__finalized {
        color: variables.$supporting-03;
        font-weight: bold;
        display: flex;
    }
    
    &__waiting {
        color: variables.$secondary;
        font-weight: bold;
        display: flex;
    }
    
    &__negative {
        color: variables.$supporting-01-dark;
        font-weight: bold;
        display: flex;
    }

    .open-icon {
        .icon {
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
            transition: all 0.5s ease;
        }
    }

    .icon {
        transition: all 0.5s ease;
    }
}

.expandable-body {
    display: none;
    background-color: variables.$white;

    &--open {
        display: flex;
        flex-direction: column;
        padding: 0 24px;
        border-top: solid 1px variables.$neutral-lighter-50;
    }
}

.expandable-button {
    display: flex;
    justify-content: flex-end;
    padding: 24px 0.5rem;
    padding-top: 0px;
    background-color: variables.$white;
}

@media (min-width: variables.$responsive-break) {
    .expandable-header {
        grid-template-columns: 1fr 50% 4fr 3fr 2fr;
        align-items: flex-start;
        justify-items: flex-end;
        padding: 16px 32px;

        &__item {
            margin-bottom: 8px;
        }

        .button {
            margin-left: -16px;
        }

        .button,
        &__items {
            justify-self: flex-start;
        }

        .expandable-header__description-title,
        .expandable-header__date,
        .expandable-header__number {
            font-weight: variables.$font-weight-bold;
        }

        &__description-title {
            @include mixins.text-three-dots(500px);
        }
    }
}

@media (max-width: variables.$responsive-break - 1) {
    .expandable-header {
        grid-template-columns: 1fr 6fr 3fr;
        grid-template-rows: 1fr 1fr auto;
        grid-template-areas:
            "button finalizedAt status" "button invoiceNo invoiceNo" "products products products";
        padding: 16px 0;

        &__items {
            grid-area: products;
            padding: 0 $expandable-heading-side-padding;

            .expandable-header__description-title {
                font-size: variables.$font-size-md;
                @include mixins.text-three-dots(68vw);
            }

            .expandable-header__product-code {
                color: variables.$neutral-darker-700;
            }
        }

        &__item-container {
            width: 100%;
        }

        &__item {
            padding: 8px 0;
        }

        &__invoice-no {
            grid-area: invoiceNo;
        }

        &__finalized-at {
            grid-area: finalizedAt;
        }

        &__status {
            grid-area: status;
            justify-self: flex-end;
            padding-right: $expandable-heading-side-padding;
        }
    }

    .expandable-body {
        &--open {
            padding: 0 8px;
        }
    }
}
