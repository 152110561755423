@use "../../../variables";
@use "../../../mixins";

.gift-bank {
    @include mixins.my-overview-section;

    &__header {
        display: flex;
        justify-content: space-between;
    }

    &__content {
        padding: 1.25rem 1.5rem;
        font-size: 16px;
    }

    &__products-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: .5rem 0;

        img {
            width: 88px;
            height: auto;
            margin-bottom: .5rem;
            padding: .75rem 1rem;
            border: solid 1px transparent;

            &:hover {
                border: solid 1px variables.$neutral-lighter-100;
                border-radius: 3px;
            }
        }
    }
}

@media(max-width: variables.$mobile-break) {
    .gift-bank {
        &__products-item {
            img {
                width: 60px;
            }
        }
    }
}