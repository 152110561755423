@use "../../../mixins";

.latest-deals {
    @include mixins.my-overview-section;

    &__content {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;

        & > div {
            flex: 1;
        }
    }

    &__no-deals {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 2rem;
        text-align: center;
    }
}
