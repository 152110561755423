@use "../../../variables";
@use "../../../mixins";

.my-contacts {
    @include mixins.my-info-container;
    & {
        flex-direction: column;
        align-items: center;
    }
    &__container {
        &--section {
            padding-top: 0;
        }

        .title__container {
            padding-top: 0;
        }
    }
}

.row.my-contacts.register h3 {
    text-align: center;
    margin-top: 24px;
}

@media (min-width: variables.$responsive-break) {
    .my-contacts {
        .separator {
            display: none;
        }
    }
}

@media (max-width: variables.$responsive-break - 1) {
    .my-contacts {
        .separator {
            border-top: solid 1px variables.$neutral-lighter-100;
            width: 100%;
        }

        &__container {
            width: 100%;

            &--section {
                padding-top: 16px;
                background: variables.$neutral-lighter-25;
                .dropdown{
                    background: variables.$white;
                }
            }
        }
    }
}
