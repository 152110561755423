@use "../../../variables";
@use "../../../mixins";

.latest-notices-item {
    border-bottom: solid 1px variables.$neutral-lighter-50;
    padding: .5rem 1.5rem;
    line-height: 24px;
    cursor: pointer;

    &:hover {
        background: variables.$neutral-lighter-50;
    }

    &--read {
        color: variables.$neutral-dark;
    }

    &__date-time {
        display: flex;
        align-items: baseline;

        & > * {
            padding-right: .5rem;
        }
    }

    @media(min-width: variables.$responsive-break) {

        &__title {
            font-weight: bold;
            @include mixins.text-three-dots(310px);
        }

        &__teaser {
            @include mixins.text-three-dots(310px);
        }
    }

    @media(max-width: variables.$responsive-break - 1) {

        &__title {
            font-weight: bold;
            @include mixins.text-three-dots(68vw);
        }

        &__teaser {
            @include mixins.text-three-dots(68vw);
        }
    }
}
