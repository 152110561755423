@use "../../../variables";

.my-page-alert {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: variables.$alert-box-radius;
  padding: 1.25rem 1.5rem;
  width: 100%;
  &--success {
    background: variables.$alert-success;
    border: solid 1px variables.$supporting-03;
    .icon {
      color: variables.$primary;
    }
  }
  &--danger {
    background: variables.$supporting-02-light;
    border: solid 1px variables.$supporting-01-dark;
    .icon {
      color: variables.$primary;
    }
  }
}

.my-page-alert__left, .my-page-alert__right {
  display: flex;
  align-items: center;
  div {
    margin-right: 1rem;
  }
}

