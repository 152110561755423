@use "../variables";

.parcel-terminal-results {
  max-height: 500px;
  overflow-y: auto;
  width: 585px;
}

.city-dropdown-container {
  padding-bottom: 24px;
  position: relative;
}

.contact-form {
  .input-field-editable {

    &--active {
      margin-top: 8px;
    }
  }
}

.manual-address {
  margin: 16px 0;
}

mark {
  font-weight: variables.$font-weight-bold;
  color: variables.$neutral-darker;
  background: transparent;
}

.no-transport {
  background: variables.$secondary-lighter;
}

.user-info .cart-transport__content {
  display: block;
}

#BillToLegalPersonContainer {
    max-width: 370px;
}

@media (max-width: variables.$small-mobile-break - 1) {
    #BillToLegalPersonContainer {
        width: 100%;
        max-width: 241px;
    }
}