@use "../../variables";

.register-corporate .section-private,
.register-private .section-corporate {
    display: none;
}

.input-field {
    font-size: variables.$font-size-default;
}

.register .button--secondary {
    border: none;
}

.section-private .cancel-change {
    margin-top: -20px;
}

.cancel-change {
    text-align: right;
    padding-top: 0.5rem;
}