@use "../../../variables";
@use "../../../mixins";

.shipping-info {
    @include mixins.my-info-container;

    .map-buttons__container {
        display: flex;
        margin-top: 8px;
    }

    .shipping-message__container {
        width: 100%;
        margin-bottom: 0;
    }
}

@media (max-width: variables.$responsive-break - 1) {
    .shipping-info {

        &__container {
            flex-direction: column;
            width: 100%;

            .city-dropdown-container {
                display: none;
            }

            .map-buttons__container {
                display: none;
            }
        }
    }
}
