@use "../../variables";
.my-saved-products {

    @media (min-width: variables.$responsive-break) {
        .product-card {
            margin-bottom: 0.5rem;
            background-color: variables.$white;
            min-height: 440px;

            &:hover:not(.landscape) {
                border: 0;
                box-shadow: none;
            }

            .monthly-payment {
                text-align: initial;
            }
        }

        .saved-product {
            display: flex;
            flex-wrap: wrap;
        }

        &__content {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 1rem;
            padding-bottom: 50px;
        }

        .title__container {
            display: flex;
            margin-bottom: 2rem;
            padding-top: 0;

            h3 {
                font-weight: variables.$font-weight-normal;
                font-size: variables.$font-size-lg;
                line-height: variables.$lh-medium;
            }
        }
    }

    @media (max-width: variables.$responsive-break - 1) {
        h3 {
            padding-left: 16px
        }
        
        .title__container h3{
            flex: auto;
        }

        .my-saved-products {
            &__content {
                display: grid;
                grid-template-columns: repeat(2, minmax(0, 1fr));
                padding: 0 1rem 2rem 1rem;
                grid-gap: 1rem;

                .button-remove {
                    margin-top: 16px;
                }

                .saved-product {
                    display: flex;
                    flex-direction: column;
                }
            }
        }
    }

    @media (max-width: variables.$mobile-break) {
        .my-saved-products {
            &__content {
                grid-template-columns: 1fr;
            }
        }
    }
}
