@use "../../../variables";

.history-product {
    border-bottom: 1px solid variables.$neutral-lighter-50;

    &__container {
        display: grid;
        padding: 16px 0 16px 0;
    }

    &__accessories {
        display: none;

        &--open {
            display: grid;
        }

        .col-12 {
            padding: unset;
        }
    }
}

.review-rating__stars {
    grid-area: stars;
    display: flex;
    justify-content: center;
    height: fit-content;
    font-size: 0.8rem;

    .icon {
        padding-right: 3px;
    }
}

@media (min-width: variables.$responsive-break) {
    .history-product {
        &__container {
            grid-template-columns: 1fr 5fr 2fr 1fr 1fr;
            grid-template-rows: repeat(4, auto);
            grid-template-areas:
                "image name           actions quantity price" "image attributes     actions .         ." "stars links          actions .         ." ".     accessoriesBtn .       .         .";
        }

        .expandable-header__thumbnail {
            grid-area: image;
        }

        &__name {
            grid-area: name;
            font-weight: variables.$font-weight-bold;
        }

        &__attributes {
            grid-area: attributes;
        }

        &__links {
            grid-area: links;
            line-height: 25px;
        }

        &__accessories-button {
            grid-area: accessoriesBtn;
        }

        &__actions {
            grid-area: actions;
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
        }

        &__quantity {
            grid-area: quantity;
            display: flex;
            justify-self: flex-end;

            span:first-child {
                display: none;
            }

            span:last-child::after {
                padding-left: 3px;
                content: "x";
            }
        }

        &__price {
            grid-area: price;
            justify-self: flex-end;
        }
    }
}

@media (max-width: variables.$responsive-break - 1) {
    .history-product {
        &__container {
            grid-template-columns: repeat(3, auto);
            grid-template-rows: repeat(6, auto);
            grid-template-areas:
                "image name price" ". quantity ." ". stars ." ". links ." "actions actions actions" "accessoriesBtn accessoriesBtn accessoriesBtn";
            justify-items: flex-start;

            & > div {
                margin: 6px 0;
            }
        }

        .expandable-header__thumbnail {
            grid-area: image;

            img {
                width: inherit;
                height: inherit;
            }
        }

        .review-rating__stars {
            font-size: variables.$font-size-md;
        }

        &__name {
            grid-area: name;
            font-size: variables.$font-size-md;
            color: variables.$primary;
        }

        &__attributes {
            display: none;
        }

        &__links {
            grid-area: links;
            line-height: 25px;
        }

        &__actions {
            grid-area: actions;
            justify-self: center;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 0.5rem;

            .button {
                &--small {
                    width: 100%;
                }
            }
        }

        &__quantity {
            grid-area: quantity;
            font-size: variables.$font-size-sm;
            color: variables.$primary;
        }

        &__price {
            grid-area: price;
            font-size: variables.$font-size-md;
        }

        &__accessories {
            padding: 16px;
            border-radius: 5px;
            border: 1px solid variables.$primary;

            &-button {
                grid-area: accessoriesBtn;
            }
        }
    }
}
