@use "../../../mixins";

.other-info {
    @include mixins.my-info-container;

    &__container {
        flex-direction: column;
        flex-wrap: nowrap;
    }

    &__selection-container {
        h4 {
            margin-bottom: 1rem;
        }

        .checkbox {
            margin-bottom: .5rem;
        }
    }
}
