@use "../../variables";

$responsive-summary-width: 80%;

.my-shopping-history {
    &__summary {
        justify-content: flex-end;

        .col-3 {
            align-items: center;

            div:first-child {
                flex: 1;
            }
        }

        h1 {
            font-size: 24px;
        }
    }

    &__buttons {
        display: flex;
        justify-content: flex-end;

        @media (min-width: variables.$responsive-break) {
            & > * {
                margin-left: 1rem;
            }
        }
    }
}

@media (max-width: variables.$responsive-break - 1) {
    .my-shopping-history__summary {
        padding: 16px calc(16px + (100% - #{$responsive-summary-width}) / 2 - 3px) 0px;
        justify-content: space-between;
        flex-direction: column;

        .col-3 {
            padding: 0;
            flex-basis: auto;
            max-width: none;

            div:first-child {
                padding-right: 5px;
            }
        }

        .col-3:only-child {
            width: 100%;
        }

        .col-3:last-child {
            padding: 16px 10%;
        }

        h1 {
            color: variables.$primary;
            font-size: variables.$font-size-md;
        }
    }

    .my-shopping-history__buttons {
        flex-direction: column;
        align-items: center;

        .button {
            width: $responsive-summary-width;
            margin-top: 8px;
            padding: 0;
        }
    }

    .customer-service-panel {
        .panel {
            &__header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 32px 24px;
                height: 100%;
                flex: 1;
                max-height: 0;
            }

            &__body {
                height: 100%;
            }

            &__body,
            &__footer {
                padding: 16px 32px;
            }
        }
    }
}
