@use '../../../variables';

.hire-purchase {
    width: 100%;

    &__name {
        color: variables.$neutral-dark;
    }

    &__value {
        font-weight: bold;
        color: variables.$neutral-darker;
    }
}
