@use "../../../variables";
@use "../../../mixins";

.my-offers-form {
  @include mixins.my-overview-section;

  &__content {
    padding: 1.25rem 1.5rem;
  }

  &__selection {
    .col-3 {
      padding: unset;
    }
  }

  &__selection-column {
    display: flex;
    flex-direction: column;
  }

  &__selection-column-title {
    margin-bottom: 1rem;
  }

  &__extra-selection {
    border-top: solid 1px variables.$neutral-lighter-50;
    padding-top: 1rem;
  }

  &__extra-selection-item {
    margin-bottom: 1.5rem;
  }
}
