@use '../../../variables';

.notification-unread {
  background-color: variables.$white;
  padding: 0 1rem .5rem 1rem;
  border-radius: variables.$border-radius-round;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__header-actions {
    display: flex;
    align-items: center;
    div {
      &:first-child {
        padding: 0 1.5rem;
        margin: .5rem 0;
      }
      &:last-child {
        padding-left: 1.5rem;
      }
    }
  }

  &__body {
    div {
      &:first-child {
        margin-bottom: .75rem;
      }
      &:not(:first-child) {
        margin-bottom: .5rem;
      }
    }
  }

  &__body-date {
    display: flex;
    color: variables.$neutral;
    div {
      padding-right: 1rem;
    }
  }

  &__body-actions {
    display: flex;
    div {
      padding-right: 1rem;
    }
  }
}
