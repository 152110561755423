@use "../../../variables";
@use "../../../mixins";

.my-limit {
    @include mixins.my-overview-section;

    &__limit-left {
        display: flex;
        flex-direction: column;
        align-items: center;
        line-height: 24px;
        margin-bottom: 2rem;
    }

    &__amount {
        font-weight: 800;
        font-size: 32px;
        line-height: 38px;
        color: variables.$primary;
    }

    &__expires, &__info {
        color: variables.$neutral-dark;
    }

    &__content {
        padding: 1.25rem 1.5rem;

        input {
            width: 100%;
        }
    }

    &__increase-limit {
        margin-bottom: 2rem;

        .input-field {
            flex-direction: column;
        }
    }

    &-header {
        .title__container {
            flex-basis: 25%;
            font-size: variables.$font-size-sm;
            padding-top: 0;
        }
    }

    &-amount {
        .input-field {

            &__input {
                max-width: 30%;
            }

            &__label {
                font-size: variables.$font-size-sm
            }

            .field-validation-error {
                padding: 9px;
            }
        }
    }
}

@media (max-width: variables.$responsive-break) {
    .my-limit {
        background-color: variables.$neutral-lighter-25;
        padding-bottom: 20px;
    }
}
