@use "../../variables";

$apply-limit-border: solid 1px variables.$neutral-lighter-100;

.apply-limit {
    background-color: variables.$white;
    border-radius: variables.$border-radius-round;

    &__header {
        border-bottom: $apply-limit-border;
        padding: 1.5rem 2rem;
    }

    &__content-first {
        border-right: $apply-limit-border;

        .buttons {
            align-self: flex-end;
            display: flex;
            width: 100%;

            .button__container {
                flex-basis: 50%;
                padding: 16px;
            }
        }
    }

    .only-private-person-msg {
        margin: auto;
        max-width: 40%;
    }

    .accordion {
        width: 100%;
    }

    .secure-signin {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 16px;
    }

    .checkbox {
        flex-direction: column;
        align-items: center;
    }
}

@media (max-width: variables.$responsive-break - 1) {
    .apply-limit {
        .my-limit {
            &-header {
                flex-direction: column;

                & > :not(form) * {
                    padding-bottom: 1rem;
                }
            }

            &-amount {
                flex-direction: column;

                .input-field {
                    flex-direction: column;

                    &__input {
                        max-width: 100%;

                        &-container {
                            flex-direction: column;
                        }
                    }
                }

                & > * {
                    margin-bottom: 1rem;
                }

                button {
                    width: 100%;
                }
            }
        }

        &__content {
            flex-direction: column;

            .hire-purchase__value {
                justify-content: flex-end;
            }

            &-first {
                max-width: 100%;

                .buttons {
                    flex-direction: column;

                    .button__container {
                        padding: 16px 0 0 0;
                    }
                }
            }
        }

        &__header {
            padding: 16px;
        }
        
        .secure-signin {
            button {
                width: 100%;
            }
        }
    }
}
