@use '../variables';

.tab-content:not(.lr-panel--active) {
    display: none;

    &.tab-content--active {
        display: block;
    }
}

@media (max-width: variables.$responsive-break - 1) {
    .tab-content {
        &.panel__wrapper--active {
            display: flex;
        }

        &.responsive-panel__wrapper {
            display: flex;
        }
    }
}