@use '../variables';

.action_button {
    border: 1px solid variables.$neutral-lighter;
    border-radius: 8px;
    margin: 4px;
    padding: 16px;
    display: flex;
    justify-content: space-between;

    &--active {
        background: variables.$neutral-lighter-100;
    }

    &__heading {
        margin: 16px 0;
        display: flex;
        justify-content: center;
    }

    &__icon {
        font-size: 16px;
    }

    &.input-validation-error {
        border: 1px solid variables.$supporting-01-dark;
    }
    &.d-none{
        display: none;
    }
}
