@use "../variables";

.reset-password__header {

  .header-title {
    display: flex;
    margin: 16px 0;

    .title {
      margin: auto 0;
      color: variables.$primary;
      font-size: variables.$font-size-2xl;
      font-weight: variables.$font-weight-normal;
    }
  }
}

.reset-password__content {
  background: variables.$white;
  padding: 30px;

  div {
    @extend .text-center !optional;
  }

  h3 {
    font-size: variables.$font-size-md;
  }

  .input-field__input {
    margin: 16px auto;
  }

  button {
    padding: 32px;
    margin-bottom: 18px;

    .button__text {
      font-size: variables.$font-size-lg;
      font-weight: variables.$font-weight-normal;
    }
  }

  .alert {
    margin: auto;
  }
}

@media (min-width: variables.$responsive-break) {
    .reset-password__content {
        .input-field__input {
            width: 350px;
        }

        button {
            width: 350px;
        }

        .reCaptcha {
            margin: 24px;

            div {
                margin: auto;
            }
        }
    }
}

@media (max-width: variables.$responsive-break - 1) {
    .reset-password__header {
        .logo-my-euronics {
            padding-left: 8px;
        }
    }
    
    .reset-password__content {
        button {
            width: 100%;
        }
        
        .reCaptcha {
            margin: 24px 0;
        }
    }
}
