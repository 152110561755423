@use "../../../variables";
@use "../../../mixins";

.latest-notices {
    @include mixins.my-overview-section;

    &__container {
        height: 100%;
    }

    &__content {
        height: 100%;
        display: flex;
    }

    &__items {
        flex: 1;
    }

    &__no-notices {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 2rem;
        text-align: center;
        flex: 1;
    }
}

@media (max-width: variables.$responsive-break) {
    .latest-notices {
        background-color: variables.$neutral-lighter-25;
        padding-bottom: 20px;
    }
}
