.add-location {
  display: none;
  width: 100%;
  & > * {
    margin-bottom: 1rem;
  }

  &__actions-container {
    display: flex;
    label {
      flex: 1 0 20%;
      padding: 0 .4rem;
    }
    .add-location__action-buttons {
      flex: 1 1 80%;
      display: flex;
      .input-field:nth-child(1) {
        padding-right: .5rem;
      }
    }
  }

  .switch label {
    padding: .5rem 1rem;
  }
}
