@use "../variables";

.product-review-panel {
    text-align: -webkit-center;
    container-name: product-review-panel;

    .panel__header{
        padding: 12px;
        &-text{
            color: variables.$primary;
            font-weight: variables.$font-weight-normal;
            font-size: variables.$font-size-lg;
        }
    }

    &__header {
        background-color: variables.$neutral-lighter-25;
        padding: 32px 96px;

        &-text p,h3{
            color: variables.$primary;
            font-weight: variables.$font-weight-normal;
            font-size: variables.$font-size-lg;
        }
    }

    &__text p {
        margin: 0 24px;
        color: variables.$primary;
        font-size:  variables.$font-size-md;
    }

    &__text-light p {
        color: variables.$neutral-dark;
    }

    &__check-box-header {
        background-color: variables.$neutral-lighter-25;
        height: 180px;
        align-items: center;
        flex-flow: column;
        display: flex;

        > h3 {
            color: variables.$primary;
            font-weight: variables.$font-weight-normal;
            font-size: variables.$font-size-xl;
        }
    }

    &__footer {
        display: flex;
        position: fixed;
        bottom: 0;
        width: -webkit-fill-available;
        margin-right: 16px;

        .button {
            margin: 0 5% 24px 5%;
        }
    }

    &__product {
        $my-review-product-border: solid 1px variables.$neutral-lighter-50;
        display: flex;
        align-items: center;
        justify-content: center;
        border: $my-review-product-border;
        padding: 1.25rem;
        margin-bottom: 2rem;
        margin-top: 2rem;
        width: fit-content;

        & > * {
            margin-right: 1rem;
        }

        img {
            width: 60px;
            height: auto;
        }
    }

    &__rating {
        display: flex;
        flex-direction: column;
        align-items: center;

        > p {
            color: variables.$primary;
        }

        .mobile-header{
            display: none;
        }
    }

    .product-mobile-title {
        display: none;
    }

    #low-rating-contact-form {
        .row {
            width: 90%;

            .input-field {
                flex-flow: column;
                align-items: flex-start;
            }
        }
    }

    #comment-form {
        .text-left {
            text-align: left;
        }
        
        .input-field {
            width: 90%;
            flex-flow: column;
            align-items: flex-start;

            &__label {
                margin-top: 16px;
                margin-bottom: 8px;
            }
        }

        .textarea{
            width: 90%;
            flex-flow: column;
        }

        #ReviewText {
            height: 300px;
        }
    }
}

.rating-star {
    @if variables.$site-nova {
        &__emote-0 {
            background-image: url('/images/novastar/rating_novanaut_0.png');
        }

        &__emote-1 {
            background-image: url('/images/novastar/rating_novanaut_1.png');
        }

        &__emote-2 {
            background-image: url('/images/novastar/rating_novanaut_2.png');
        }

        &__emote-3 {
            background-image: url('/images/novastar/rating_novanaut_3.png');
        }

        &__emote-4 {
            background-image: url('/images/novastar/rating_novanaut_4.png');
        }
        &__emote-0-m {
            background-image: url('/images/novastar/rating_novanaut_0-m.png');
        }

        &__emote-1-m {
            background-image: url('/images/novastar/rating_novanaut_1-m.png');
        }

        &__emote-2-m {
            background-image: url('/images/novastar/rating_novanaut_2-m.png');
        }

        &__emote-3-m {
            background-image: url('/images/novastar/rating_novanaut_3-m.png');
        }

        &__emote-4-m {
            background-image: url('/images/novastar/rating_novanaut_4-m.png');
        }
    }
    @else if variables.$site-avi {

        &__emote-0 {
            background-image: url('/images/avitela/rating_star_emote_0.svg');
        }

        &__emote-1 {
            background-image: url('/images/avitela/rating_star_emote_1.svg');
        }

        &__emote-2 {
            background-image: url('/images/avitela/rating_star_emote_2.svg');
        }

        &__emote-3 {
            background-image: url('/images/avitela/rating_star_emote_3.svg');
        }

        &__emote-4 {
            background-image: url('/images/avitela/rating_star_emote_4.svg');
        }
    }
    
    @else {

        &__emote-0 {
            background-image: url('/images/rating_star_emote_0.svg');
        }

        &__emote-1 {
            background-image: url('/images/rating_star_emote_1.svg');
        }

        &__emote-2 {
            background-image: url('/images/rating_star_emote_2.svg');
        }

        &__emote-3 {
            background-image: url('/images/rating_star_emote_3.svg');
        }

        &__emote-4 {
            background-image: url('/images/rating_star_emote_4.svg');
        }
    }

    &__unfilled {
        background-image: url('/images/rating_star.svg');
    }

    &__filled {
        background-image: url('/images/rating_star_filled.svg');
    }
}

.rating-button-star{
    height: 20px;
    width: 20px;
    background-image: url('/images/small_blue_star.svg');
}

.green-check-mark {
    height: 41px;
    width: 41px;
    background-image: url('/images/check-mark.svg');
}

.emote-rating-star {
    background-size: 78px 89px;
    width: 78px;
    height: 89px;
    display: inline-flex;
}

.review-answer {
    background: variables.$neutral-lighter-25;
    margin: 10px 0 10px 10px;
    padding: 10px;
}

@media(min-width: variables.$large-responsive-break) {
    #product-review-panel.lr-panel--active .panel-right {
        width: 25%;
        left: 75%;
    }
    .product-review-panel__header {
        padding: 32px 128px;
    }
}

@media(max-width: variables.$responsive-break) {
    #product-review-panel {
        .responsive-panel {
            padding: 0;
            min-height: fit-content;
            &__back{
                text-align: left;
                margin-left: 10px;
            }
        }
    }

    .product-review-panel #comment-form #ReviewText {
        height: unset;
    }
}

@media(max-width: variables.$mobile-break - 1) {

    .product-review-panel {
        &__product {
            margin-left: 16px;
            margin-right: 16px;
            margin-top: 1rem;
        }

        &__header {
            padding: 32px;
        }

        #product-review-panel-rating{
            &__header{
                display: none;
            }
            .mobile-header{
                display: unset;
            }
            .regular-header{
                display: none;
            }
        }

        &__footer {
            margin-right: 0;

            .button {
                margin-bottom: 24px;
            }
        }

        .panel__header {
            display: none;
        }
    }

    #product-review-panel-rating.d-none.product-review-panel{
        display: none;
    }

    #product-review-panel-rating {
        .product-review-panel-rating-container{
            display: flex;
            flex-flow: column-reverse;
            align-items: center;
            background: linear-gradient(variables.$neutral-lighter-25 50%, white 50%);
            justify-content: center;

            .rating-star {
                @if variables.$site-nova {
                    &__emote-0 {
                        background-image: url('/images/novastar/rating_novanaut_0-m.png');
                    }

                    &__emote-1 {
                        background-image: url('/images/novastar/rating_novanaut_1-m.png');
                    }

                    &__emote-2 {
                        background-image: url('/images/novastar/rating_novanaut_2-m.png');
                    }

                    &__emote-3 {
                        background-image: url('/images/novastar/rating_novanaut_3-m.png');
                    }

                    &__emote-4 {
                        background-image: url('/images/novastar/rating_novanaut_4-m.png');
                    }
                }
            }
            .emote-rating-star {
                margin-top: 20px;
            }


            .product-review-panel {
                &__header {
                    display: none;
                }

                &__rating {
                    margin-bottom: 100px;
                }
            }

            .product-mobile-title {
                display: unset;
            }
        }
    }
}

@media(max-width: variables.$small-mobile-break - 1) {
    .product-review-panel {
        &__footer .button {
            min-width: unset;

        }
    }
}

@media(max-height: 820px) {
    .product-review-panel__footer{
        position: unset;
        margin-top: 20px;
    }
}