@use "../../../variables";
@use "../../../mixins";

.latest-deals-item {
  display: flex;
  border-bottom: solid 1px variables.$neutral-lighter-50;
  padding: .5rem 1.5rem;
  line-height: 24px;

  &__thumbnail {
    text-align: center;
    margin-right: 1rem;
    img {
      width: 48px;
      height: auto;
    }
  }

  &__info-container {
    display: flex;
    flex-direction: column;
    & > * {
      margin-bottom: .5rem;
    }
  }

  &__name {
    font-weight: bold;
    @include mixins.text-three-dots(250px);
  }

  &__date {
    display: flex;
    align-items: baseline;
    & > * {
      padding-right: .5rem;
      &:not(a) {
        color: variables.$neutral-dark;
      }
    }
  }
}

@media(max-width: variables.$responsive-break - 1){
    .latest-deals-item {
        &__name {
            max-width: 68vw;
        }
    }
}