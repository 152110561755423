@use "../../variables";
@use "../../mixins";

.user-info__main {
    background-color: variables.$neutral-lighter-25;
    padding: 2rem 0;
}

.title__container {
    width: 100%;
    padding-top: 16px;

    .logo-my-euronics {
        display: none;
    }
}

@media (min-width: variables.$responsive-break) {
    .tab-content {
        & > .title__container {
            display: none;
        }
    }
}

@media (max-width: variables.$responsive-break - 1) {
    .user-info {
        .title__container {
            display: flex;
            justify-content: center;
            align-items: center;

            .logo-my-euronics {
                display: inline;
                padding-right: 16px;
            }

            h3 {
                @include mixins.responsive-title;
                flex: none;
                margin-bottom: 0;
            }
        }
    }

    .tab-content--active {
        background: variables.$white;
    }
}
