@use '../../../variables';

.notification-read {
  background-color: variables.$white;
  padding: 0 1rem .5rem 1rem;
  border-radius: variables.$border-radius-round;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h4, .button {
      color: variables.$neutral-dark;
    }
  }

  &__body-date {
    display: flex;
    color: variables.$neutral;
    div {
      padding-right: 1rem;
    }
  }

  .notification-read__header-button {
    color: variables.$supporting-01;
  }
}
