@use "../../variables";

.my-notifications {
    &__messages {
        width: 100%;
        padding: 0px 16px;

        .title__container {
            display: none;
        }
    }

    .notification-unread, .notification-read {
        margin-bottom: 1rem;
        min-height: 60px;
        padding: 1rem 1rem .5rem 1rem;
    }

    .more-separator {
        margin: 2rem 0;
    }
}

@media(max-width: variables.$responsive-break - 1) {
    .more-separator {
        display: none;
    }

    .border__light--responsive {
        border: solid 1px variables.$neutral-lighter-50;
        border-radius: 5px;
    }

    .title__container {
        padding-bottom: 16px;
    }

    .my-notifications {
        &__messages {
            .title__container {
                display: flex;
            }
        }
    }
}
