@use "../../../variables";

$input-padding: 8px;

.shopping-history-search-form {
    display: flex;

    .icon {
        color: variables.$primary;
    }

    &__button {
        width: 128px;

        button {
            padding: 8px 0;
        }
    }

    &__fields {
        display: flex;
    }
}

@media (min-width: variables.$responsive-break) {
    #ShoppingHistoryDateContainer {
        display: flex;

        .input-field:first-child {
            padding-right: 1rem;
        }
    }

    .shopping-history-search-form {
        &__keyword {
            width: 280px;
        }

        & > * {
            padding-right: 1rem;
        }

        .clear-filter-button {
            .button__text {
                display: none;
            }
        }
    }
}

@media (max-width: variables.$responsive-break - 1) {
    .user-info__main {
        padding: 0;
    }

    #_History {
        input {
            border-radius: 5px;
        }
    }

    .shopping-history-search-form {
        width: 100%;
        flex-direction: column;

        &__keyword {
            padding-bottom: $input-padding;
        }

        #ShoppingHistoryDateContainer {
            display: flex;
            flex-grow: 1;

            .input-field:first-child {
                padding-right: $input-padding;
            }
        }

        .clear-filter-button {
            .button__text {
                display: inline-flex;
            }

            .icon {
                display: none;
            }
        }

        &__fields {
            flex-direction: column;

            #clear-history-search-container {
                align-self: end;
            }
        }
    }
}
