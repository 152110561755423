@use "../../variables";

.my-offers {

    .title__container {
        display: flex;
        padding-top: 0;
        margin-bottom: 2rem;

        h3 {
            flex: auto;
            font-weight: variables.$font-weight-normal;
            font-size: variables.$font-size-lg;
        }
    }

    @media (max-width: variables.$responsive-break - 1) {

        .title__container {

            padding-top: 16px;
            margin-bottom: 0;

            h3 {
                font-size: variables.$font-size-xl;
            }
        }

        .carousel-card {
            display: grid;
            grid-template-columns: 1fr 2fr;
        }

        .items-carousel {
            &__item {
                margin: 0px 16px 16px;
            }

            &__header {
                display: none;
            }
        }
    }
}
