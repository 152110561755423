@use "../../variables";
@use "../../mixins";

.my-page-header {
    display: flex;
    flex-direction: column;

    &__title {
        font-weight: normal;
        color: variables.$primary;
        padding: 1rem 0;
        display: flex;
        align-items: center;

        .logo-my-euronics {
            padding-right: 16px;
        }
    }
}

@media (max-width: variables.$responsive-break - 1) {

    .my-page-header__title {
        display: none;
    }

    .responsive-sort {
        @include mixins.scrollbars;
        & {
            display: block;
            overflow-x: auto;
            overflow-y: hidden;
            white-space: nowrap;
            height: 100%;
        }
    
    }

    .tabs-menu--category {
        height: 50px;
        border-bottom: 1px solid variables.$neutral-lighter-100;

        .tabs-menu__list--primary {
            background: variables.$white;
            display: flex;
            height: 100%;

            .tabs-menu__item {
                text-align: center;
                vertical-align: middle;
                border-left: 1px solid variables.$neutral-lighter-100;

                h2 {
                    font-size: variables.$font-size-sm;
                    font-weight: variables.$font-weight-normal;
                    color: variables.$neutral-darker;
                    padding: 17px 24px;
                }

                &:hover {
                    cursor: pointer;
                    background: variables.$neutral-lighter-25;
                }
            }

            .tabs-menu__item:last-of-type {
                border-right: 1px solid variables.$neutral-lighter-100;
            }

            .tabs-menu__link {
                color: variables.$primary;
                font-weight: variables.$font-weight-normal;
                font-size: variables.$font-size-default;
                padding: 14px;

                &:hover {
                    &:not(.tabs-menu__link--active) {
                        color: variables.$primary-dark;
                    }
                }

                &--active {
                    font-weight: variables.$font-weight-bold;
                    background-color: variables.$primary-lighter-10;
                }
            }
        }
    }
}
